<template>
  <div class="present">
    <div class="yiqi" style="margin: 0; padding: 0">
      <div>
        <div @click="num = 0" :class="{ colors: num == 0 }">
          <i class="el-icon-s-order"></i>封面
        </div>
        <div @click="zouyibu" :class="{ colors: num == 1 && isShow == true }">
          <i class="el-icon-s-data"></i> 基本信息表
        </div>
        <div @click="zouyibus" :class="{ colors: num == 1 && isShow == false }">
          <i class="el-icon-s-data"></i> 推荐意见表
        </div>
        <div @click="num = 2" :class="{ colors: num == 2 }">
          <i class="el-icon-s-order"></i>上传申报正文
        </div>
        <div @click="num = 3" :class="{ colors: num == 3 }">
          <i class="el-icon-s-data"></i>上传申报附件
        </div>
        <div @click="num = 4" :class="{ colors: num == 4 }">
          <i class="el-icon-s-data"></i>提交申报材料
        </div>
      </div>
      <div class="main">
        <!-- <el-steps :active="num" align-center>
          <el-step title="封面" @click.native="num = 0"></el-step>
          <el-step
            title="填写基本资料和推荐意见表格"
            style="line-height: 25px"
            @click.native="fanhui"
          ></el-step>
          <el-step title="请上传word文档" @click.native="fanhui1"></el-step>
          <el-step title="请上传pdf文档" @click.native="num = 3"></el-step>
        </el-steps> -->
        <div v-if="num == 0">
          <div class="fengmian" id="printTest" ref="imageWrapper">
            <div>
              <div>2023年产学研合作创新奖（单位）</div>
              <div>申报材料</div>
            </div>
            <div style="width: 80%; padding: 0 180px; box-sizing: border-box">
              <div>
                <div style="margin: 40px 0">申报单位:(加盖公章)</div>
                <input
                  type="text"
                  style="width: 100%"
                  v-model="fengmian.cover_unit"
                  v-if="iss"
                />
                <div
                  style="
                    text-align: left;
                    border-bottom: 1px solid #999;
                    width: 100%;
                  "
                  v-else
                  v-html="fengmian.cover_unit"
                ></div>
              </div>
              <div>
                <div>申报时间：</div>
                <input
                  type="text"
                  style="width: 100%"
                  v-if="iss"
                  v-model="fengmian.cover_time"
                />
                <div
                  style="
                    text-align: left;
                    border-bottom: 1px solid #999;
                    width: 100%;
                  "
                  v-else
                  v-html="fengmian.cover_time"
                ></div>
              </div>
            </div>
          </div>
          <div class="feng_btn">
            <el-button @click="xiaxia" type="primary">保存</el-button>
            <el-button @click="xiaxiadayin" type="primary">下载</el-button>
          </div>
        </div>
        <div v-if="num == 1" class="shenMain">
          <div class="tabls" v-if="isShow">
            <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
              <div>
                <h3 class="zzzzzz">申报单位基本信息</h3>
              </div>
              <table border="1" cellpading="0" cellspace="0">
                <tbody>
                  <tr>
                    <td>单位名称</td>
                    <td colspan="5">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.work_unit"
                      />
                    </td>
                    <td>单位性质</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.nature_of_unit"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>单位地址</td>
                    <td colspan="5">
                      <!-- <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.unit_address"
                      /> -->
                      <textarea
                        v-if="iss"
                        type="text"
                        style="
                          border: 1px solid #000;
                          width: 100%;

                          height: 100%;
                          box-sizing: border-box;
                          padding: 0 10px;
                          font-size: 28px;
                        "
                        v-model="xinxi.unit_address"
                      />
                      <div
                        style="
                          text-align: left;
                          box-sizing: border-box;
                          padding: 0 10px;
                        "
                        v-else
                        v-html="xinxi.unit_address"
                      ></div>
                    </td>
                    <td>邮编</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.postcode"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1" style="width: 100px">电子邮箱</td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.unit_email"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                    <td colspan="1">
                      电话
                      <div>(加区号)</div>
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.mobile"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                    <td colspan="1">信用代码</td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.credit_code"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1" style="width: 100px">
                      单位负责人
                      <div>或法人姓名</div>
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.user_name"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                    <td colspan="1">职务</td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.job"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                    <td colspan="1">职称</td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.skill"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td>
                      电话
                      <div>(加区号)</div>
                    </td>
                    <td colspan="5">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.unit_tel"
                      />
                    </td>
                    <td>手机</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.unit_mobile"
                      />
                    </td>
                  </tr>

                  <tr>
                    <td>联系部门</td>
                    <td colspan="5">
                      <!-- <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.department"
                      /> -->
                      <textarea
                        v-if="iss"
                        type="text"
                        style="
                          border: 1px solid #000;
                          width: 100%;

                          height: 100%;
                          box-sizing: border-box;
                          padding: 0 10px;
                          font-size: 28px;
                        "
                        v-model="xinxi.department"
                      />
                      <div
                        style="
                          text-align: left;
                          box-sizing: border-box;
                          padding: 0 10px;
                        "
                        v-else
                        v-html="xinxi.department"
                      ></div>
                    </td>
                    <td>联系人</td>
                    <td colspan="2">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                        v-model="xinxi.department_mobile"
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1" style="width: 100px">电子邮箱</td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.email"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                    <td colspan="1">
                      电话
                      <div>(加区号)</div>
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.tel_mobile"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                    <td colspan="1">手机</td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.mobile_phone"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1" style="width: 100px">
                      单位职
                      <div>工人数</div>
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.unit_num"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                    <td colspan="1">
                      大专以上人员
                      <div>占职工比例</div>
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.unit_num_ratio"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                    <td colspan="1">
                      单位
                      <div>总资产</div>
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.unit_asset"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="1" style="width: 100px">上一年总收入</td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.old_year_earning"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                    <td colspan="1">
                      上一年
                      <div>利润总额</div>
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.old_year_profit"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                    <td colspan="1">
                      上一年
                      <div>政府资助</div>
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.old_year_government"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2" style="width: 100px">上一年研发经费投入</td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.old_year_expenditure"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                          text-align: center;
                        "
                      />
                    </td>

                    <td colspan="2">
                      上一年研发经费占
                      <div>单位总成本的比例</div>
                    </td>
                    <td colspan="2">
                      <input
                        type="text"
                        v-model="xinxi.old_year_ratio"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                          text-align: center;
                        "
                      />
                    </td>
                  </tr>
                  <tr>
                    <td height="220px">
                      申报
                      <div>单位意见</div>
                    </td>
                    <td colspan="8" height="420px">
                      <div class="biaoge">
                        <div class="biaogesss">
                          <div>
                            本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                          </div>
                        </div>
                        <div>
                          <div>科研管理部门（盖章）</div>
                          <div>单位（盖章）</div>
                        </div>
                        <div class="faren"></div>
                      </div>
                    </td>
                  </tr>
                  <tr>
                    <td colspan="2">法定代表签字</td>
                    <td colspan="3">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                          text-align: center;
                        "
                      />
                    </td>
                    <td colspan="2">日期</td>
                    <td colspan="4">
                      <input
                        type="text"
                        style="
                          width: 100%;
                          padding: 0 10px;
                          box-sizing: border-box;
                          text-align: center;
                        "
                      />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="feng_btn">
              <el-button @click="clickXia" type="primary">保存</el-button>
              <el-button @click="clickXias" type="primary">下载</el-button>
            </div>
          </div>
          <div v-else>
            <div class="tabls">
              <div id="printTest" ref="imageWrapper" style="padding-top: 100px">
                <div>
                  <h3 class="zzzzzz">推荐意见</h3>
                </div>
                <table border="1" cellpading="0" cellspace="0">
                  <tbody>
                    <tr>
                      <td>
                        推荐单位
                        <div>/推荐专家</div>
                      </td>
                      <td colspan="4">
                        <!-- <input
                          type="text"
                          v-model="xinxi1.recommend"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                        /> -->
                        <textarea
                          v-if="iss"
                          type="text"
                          style="
                            border: 1px solid #000;
                            width: 100%;

                            height: 80%;
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-size: 28px;
                          "
                          v-model="xinxi1.recommend"
                        />
                        <div
                          style="
                            text-align: left;
                            box-sizing: border-box;
                            padding: 0 10px;
                          "
                          v-else
                          v-html="xinxi1.recommend"
                        ></div>
                      </td>
                      <td>
                        信用代码
                        <div>/身份证号</div>
                      </td>
                      <td colspan="3">
                        <input
                          type="text"
                          v-model="xinxi1.recommend_credit_code"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>通信地址</td>
                      <td colspan="4">
                        <!-- <input
                          type="text"
                          v-model="xinxi1.mailing_address"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                        /> -->
                        <textarea
                          v-if="iss"
                          type="text"
                          style="
                            border: 1px solid #000;
                            width: 100%;

                            height: 80%;
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-size: 28px;
                          "
                          v-model="xinxi1.mailing_address"
                        />
                        <div
                          style="
                            text-align: left;
                            box-sizing: border-box;
                            padding: 0 10px;
                          "
                          v-else
                          v-html="xinxi1.mailing_address"
                        ></div>
                      </td>
                      <td>邮编</td>
                      <td colspan="3">
                        <input
                          type="text"
                          v-model="xinxi1.recommend_code"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        联系人
                        <div>/推荐专家</div>
                      </td>
                      <td colspan="4">
                        <!-- <input
                          type="text"
                          v-model="xinxi1.recommend_name"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                        /> -->
                        <textarea
                          v-if="iss"
                          type="text"
                          style="
                            border: 1px solid #000;
                            width: 100%;

                            height: 80%;
                            box-sizing: border-box;
                            padding: 0 10px;
                            font-size: 28px;
                          "
                          v-model="xinxi1.recommend_name"
                        />
                        <div
                          style="
                            text-align: left;
                            box-sizing: border-box;
                            padding: 0 10px;
                          "
                          v-else
                          v-html="xinxi1.recommend_name"
                        ></div>
                      </td>
                      <td>联系电话</td>
                      <td colspan="3">
                        <input
                          type="text"
                          v-model="xinxi1.recommend_tel"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                        />
                      </td>
                    </tr>
                    <tr>
                      <td height="80px">电子邮箱</td>
                      <td colspan="4">
                        <input
                          type="text"
                          v-model="xinxi1.recommend_email"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                        />
                      </td>
                      <td>手机</td>
                      <td colspan="3">
                        <input
                          type="text"
                          v-model="xinxi1.recommend_mobile"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                        />
                      </td>
                    </tr>

                    <!-- <tr>
                      <td colspan="1" height="320px">
                        推荐专家介绍(单位、职务职称、研究领域等)
                      </td>
                      <td colspan="8" height="320px">
                        <div>
                          <el-input
                            v-if="iss"
                            type="textarea"
                            v-model="xinxi1.recommend_zhuanjia"
                            cols="40"
                            rows="8"
                            style="font-size: 24px"
                            placeholder="提示：推荐专家应为院士或者同行业领域二级教授、表格中必须填写专家本人联系方式"
                          ></el-input>
                          <div
                            style="
                              text-align: left;
                              box-sizing: border-box;
                              padding: 15px 10px;
                            "
                            v-else
                            v-html="xinxi1.recommend_zhuanjia"
                          ></div>
                          <div
                            style="
                              height: 60px;
                              display: flex;
                              justify-content: center;
                              align-items: center;
                            "
                            v-if="iss"
                          >
                            提示：推荐专家应为院士或者同行业领域二级教授、表格中必须填写专家本人联系方式
                          </div>
                        </div>
                      </td>
                    </tr> -->
                    <td colspan="8" height="320px">
                      <div style="width: 95%; text-align: left; margin: 15px">
                        推荐单位介绍/专家介绍(单位、职务职称、研究领域等):
                      </div>
                      <div>
                        <el-input
                          v-if="iss"
                          type="textarea"
                          v-model="xinxi1.recommend_zhuanjia"
                          cols="40"
                          rows="8"
                          style="font-size: 30px"
                          placeholder="提示：推荐专家应为院士或者同行业领域二级教授、表格中必须填写专家本人联系方式"
                        ></el-input>

                        <div
                          style="
                            text-align: left;
                            box-sizing: border-box;
                            padding: 0 10px;
                          "
                          v-else
                          v-html="xinxi1.recommend_zhuanjia"
                        ></div>
                        <div
                          style="
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: left;
                          "
                          v-if="iss"
                        >
                          提示：推荐专家应为院士或者同行业领域二级教授、表格中必须填写专家本人联系方式
                        </div>
                        <div
                          style="
                            height: 30px;
                            display: flex;
                            justify-content: center;
                            align-items: left;
                          "
                          v-if="iss"
                        >
                          (推荐单位与推荐专家二选一)
                        </div>
                      </div>
                    </td>
                    <tr>
                      <!-- <td colspan="1" height="320px">推荐意见</td> -->
                      <td colspan="8" height="320px">
                        <div style="width: 95%; text-align: left; margin: 15px">
                          推荐意见:
                        </div>
                        <el-input
                          v-if="iss"
                          type="textarea"
                          v-model="xinxi1.recommend_opinion"
                          cols="40"
                          style="font-size: 24px"
                          rows="6"
                        ></el-input>
                        <div
                          style="
                            text-align: left;
                            padding: 0 10px;
                            box-sizing: border-box;
                          "
                          v-else
                          v-html="xinxi1.recommend_opinion"
                        ></div>
                        <div
                          style="margin: 30px 20px 20px 0; text-align: right"
                        >
                          推荐专家签字/推荐单位（盖章）
                        </div>
                        <div style="text-align: right">
                          <div
                            style="
                              display: flex;
                              margin: 0 120px 80px 0;
                              flex-direction: row-reverse;
                            "
                          >
                            <span>日</span
                            ><span style="display: block; margin: 0 30px"
                              >月</span
                            ><span>年</span>
                          </div>
                        </div>
                        <!-- <div class="biaoge">
                          <div>
                            <div></div>
                            <div>推荐专家签字/推荐单位（盖章）</div>
                          </div>
                          <div class="faren"></div>
                        </div> -->
                      </td>
                    </tr>
                    <!-- <tr>
                      <td colspan="1" height="320px">推荐意见</td>
                      <td colspan="8" height="320px">
                        <el-input
                          v-if="iss"
                          type="textarea"
                          v-model="xinxi1.recommend_opinion"
                          cols="40"
                          rows="8"
                          style="font-size: 24px"
                        ></el-input>
                        <div
                          style="
                            text-align: left;
                            box-sizing: border-box;
                            padding: 15px 10px;
                          "
                          v-else
                          v-html="xinxi1.recommend_opinion"
                        ></div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="8" height="220px">
                        <div class="biaoge">
                          <div>
                            <div class="biaoges">
                              本单位遵守《中国产学研合作促进会产学研合作创新与促进奖奖励条例》的有关规定，承诺遵守评审工作纪律，所提供的申报材料真实有效，且不存在任何违反《中华人民共和国保守国家秘密法》和《科学技术保密规定》等相关法律法规及侵犯他人知识产权的情形。如有材料虚假及违纪行为，愿意承担相应责任并接受相应处理。如产生争议，保证积极调查处理。
                            </div>
                          </div>
                          <div>
                            <div></div>
                            <div>推荐专家签字/推荐单位（盖章）</div>
                          </div>
                          <div class="faren"></div>
                        </div>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2">日期</td>
                      <td colspan="6">
                        <input
                          type="text"
                          style="
                            width: 100%;
                            padding: 0 10px;
                            box-sizing: border-box;
                            text-align: center;
                          "
                          v-model="xinxi1.recommend_date"
                        />
                      </td>
                    </tr> -->
                  </tbody>
                </table>
              </div>
            </div>
            <div class="feng_btn">
              <el-button @click="xiayibu1" type="primary">保存</el-button>
              <el-button @click="xiayibu1s" type="primary">下载</el-button>
            </div>
          </div>
        </div>
        <div v-else-if="num == 2">
          <div class="shangchuan" v-if="isShows == 1">
            <div>
              <el-button @click="xiazai" style="margin-bottom: 20px"
                >下载模板</el-button
              >
              <div style="margin-bottom: 20px">
                请先下载模板，并依照模板填写内容，填写后，同时生成pdf文件上传至平台。
              </div>
              <el-upload
                style="
                  display: flex;
                  flex-direction: column;
                  align-items: center;
                "
                class="upload-demo"
                ref="upload"
                action
                :http-request="uploadFile"
                :on-change="handleChange"
                :file-list="fileList"
                :on-preview="handlePictureCardPreview"
                :show-file-list="false"
              >
                <el-button type="primary">添加文件</el-button>
                <div slot="tip" class="el-upload__tip">只能上传PDF文档</div>
              </el-upload>
            </div>
            <div
              v-if="fileList.length != 0"
              style="
                display: flex;
                justify-content: center;
                align-items: center;
              "
            >
              <el-button type="primary" @click="ylan">预览</el-button>
              <el-button @click="xiaoxiabu" type="primary">保存</el-button>
            </div>
          </div>
        </div>
        <div v-else-if="num == 3">
          <div
            style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 20px 0;
            "
          >
            <el-alert
              title="提示：添加完文件后，请点击上传按钮。PDF文件名中请勿带有小数点和特殊符号，文件需控制在30M以下"
              type="success"
              style="width: 70%; font-size: 22px"
            ></el-alert>
          </div>
          <div class="shangchuan1">
            <div class="box">
              <div>申报人基本信息表盖章签字扫描件（必上传带水印版）</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory1)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory1 == null || obj.accessory1 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile1"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory1', 1)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
            <div class="box">
              <div>推荐意见表盖章签字扫描件（必上传带水印版）</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory2)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory2 == null || obj.accessory2 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile2"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory2', 2)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
            <div class="box">
              <div>企业营业执照、事业法人证书、社团登记证扫描件（必上传）</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory3)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory3 == null || obj.accessory3 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile3"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory3', 3)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
            <div class="box">
              <div>知识产权相关证明扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory4)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory4 == null || obj.accessory4 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile4"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory4', 4)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
            <div class="box">
              <div>获奖证书扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory5)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory5 == null || obj.accessory5 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile5"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory5', 5)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
            <div class="box">
              <div>产学研合作协议、研发合作协议扫描件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory6)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory6 == null || obj.accessory6 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile6"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory6', 6)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
            <div class="box">
              <div>
                产学研合作成果列入政府资助项目、各项计划相关文件（国家级、省级、市级）扫描件
              </div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory7)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory7 == null || obj.accessory7 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile7"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory7', 7)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
            <div class="box">
              <div>获得金融机构、风投等单位相关支持文件</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory8)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory8 == null || obj.accessory8 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile8"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory8', 8)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
            <div class="box">
              <div>上一年度资产负债表、利润表</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory9)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory9 == null || obj.accessory9 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile9"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory9', 9)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
            <div class="box">
              <div>其他相关证明</div>
              <div>
                <div>
                  <el-button
                    type="primary"
                    @click="kan(obj.accessory10)"
                    style="width: 113px; height: 45px"
                    >{{
                      obj.accessory10 == null || obj.accessory10 == ""
                        ? "空"
                        : "预览"
                    }}</el-button
                  >
                </div>
                <div>
                  <el-upload
                    class="upload-demo"
                    ref="upload"
                    :file-list="normal"
                    action
                    :http-request="uploadFile10"
                    :show-file-list="false"
                  >
                    <el-button type="primary" style="width: 113px; height: 45px"
                      >添加文件</el-button
                    >
                  </el-upload>
                </div>
                <div>
                  <el-button
                    type="danger"
                    @click="dels('accessory10', 10)"
                    style="width: 113px; height: 45px"
                    >删除</el-button
                  >
                </div>
              </div>
            </div>
          </div>
          <div class="feng_btn">
            <el-button @click="wancheng" type="primary">上传</el-button>
          </div>
        </div>
        <div class="zuihou" v-else-if="num == 4">
          <dir>
            <div>
              <h3>本人知悉中国产学研合作创新与促进奖申报条件，了解有关要求</h3>
            </div>
            <div>1.遵守国家法律法规、社会道德风尚和科研诚信要求。</div>
            <div>
              2.填写的材料真实有效，不存在弄虚作假、侵占他人成果等行为，本人对其客观性和真实性负责。
            </div>
            <div>
              3.填写的材料符合国家保密法律法规和奖项保密要求，不涉及国家秘密，可用于评审。
            </div>
            <div style="color: red">
              <input
                class="chea"
                name="Fruit"
                type="checkbox"
                v-model="showss"
                value=""
              />本人对以上内容及全部申报附件材料的客观性和真实性负责
            </div>
            <div>
              <el-button @click="wancheng1" type="primary">确认提交</el-button>
              <el-button @click="daochus" type="primary">打包下载</el-button>
            </div>
          </dir>
        </div>
      </div>
    </div>

    <el-dialog
      :show-close="false"
      :visible.sync="centerDialogVisible"
      width="700px"
      center
    >
      <div class="tijiaos">
        <div>提交成功</div>
        <div style="font-weight: bold; font-size: 24px">
          重要揭示: 请严格按照纸质版材料装订要求进行装订
        </div>
        <div>请于3年9月10日前将纸质申报材料快递至促进会评奖办</div>
        <div>地址: 北京市海淀区学清路18号院8幢中国产学研合作促进会</div>
        <div>电话:(010)68987182</div>
        <div>
          <div @click="tishi1">下载装订要求</div>
          <div @click="centerDialogVisible = false">返回</div>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import { saveAs } from "file-save";
import html2canvas from "html2canvas";
// import Print from "vue-print-nb";
// import Vue from "vue";
// Vue.use(Print);
import mammoth from "mammoth";
import {
  upload_file,
  user_chile_details,
  innovation_unit_will,
  award_find,
  user_file,
  upload_pdf,
  preview,
  file_download,
  del_file,
} from "../axios/api";
import print from "print-js";
export default {
  data() {
    return {
      fengmian: {},
      showss: false,
      obj: {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: "4",
        accessory1: "",
        accessory2: "",
        accessory3: "",
        accessory4: "",
        accessory5: "",
        accessory6: "",
        accessory7: "",
        accessory8: "",
        accessory9: "",
        accessory10: "",
      },
      url: "",
      id: "",
      is: true,
      iss: true,
      textarea: "",
      fileList: [],
      normal: [],
      user_name: "",
      num: 0,
      isShow: true,

      xinxi: {},
      xinxi1: {},
      vHtml: "",
      centerDialogVisible: false,
      pdfUrl: "",
      isShows: 1,
      isShowss: 1,
      user: { name: "", age: "" },
      stu: [
        { name: "张三", age: 17 },
        { name: "里斯", age: 18 },
        { name: "王五", age: 19 },
      ],
      user_split_id: "",
    };
  },
  created() {
    this.user_chile_details();
  },
  components: {},

  methods: {
    tishi1() {
      window.open(`https://note.ciur.org.cn/tishi1.pdf`);
    },
    dels(val, type) {
      let data = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: type,
      };
      del_file(data).then((res) => {
        if (res.data.code == 200) {
          this.obj[val] = null;
          this.$message({
            message: "删除成功",
            type: "success",
          });
        }
      });
    },
    zouyibu() {
      this.isShow = true;
      this.num = 1;
    },
    zouyibus() {
      this.isShow = false;
      this.num = 1;
    },
    user_chile_details() {
      let params = {
        token: sessionStorage.getItem("Token"),
      };
      user_chile_details(params).then((res) => {
        console.log(res);
        this.user_split_id = res.data.data.id;
        this.id = res.data.data.id;
        this.fengmian = {
          token: sessionStorage.getItem("Token"),
          id: res.data.data.id,
          type: "5",
          cover_unit: res.data.data.cover_unit,
          cover_time: res.data.data.cover_time,
        };
        this.obj = {
          token: sessionStorage.getItem("Token"),
          id: this.id,
          type: "4",
          accessory1: res.data.data.accessory1,
          accessory2: res.data.data.accessory2,
          accessory3: res.data.data.accessory3,
          accessory4: res.data.data.accessory4,
          accessory5: res.data.data.accessory5,
          accessory6: res.data.data.accessory6,
          accessory7: res.data.data.accessory7,
          accessory8: res.data.data.accessory8,
          accessory9: res.data.data.accessory9,
          accessory10: res.data.data.accessory10,
        };
        if (res.data.data.user_data != null) {
          this.url = res.data.data.user_data;
          this.fileList.push(this.url);
        }
        this.xinxi = {
          id: res.data.data.id,
          type: 1,
          work_unit: res.data.data.work_unit,
          nature_of_unit: res.data.data.nature_of_unit,
          unit_address: res.data.data.unit_address,
          postcode: res.data.data.postcode,
          unit_email: res.data.data.unit_email,
          mobile: res.data.data.mobile,
          credit_code: res.data.data.credit_code,
          user_name: res.data.data.user_name,
          job: res.data.data.job,
          skill: res.data.data.skill,
          unit_tel: res.data.data.unit_tel,
          unit_mobile: res.data.data.unit_mobile,
          department: res.data.data.department,
          department_mobile: res.data.data.department_mobile,
          email: res.data.data.email,
          tel_mobile: res.data.data.tel_mobile,
          mobile_phone: res.data.data.mobile_phone,
          unit_num: res.data.data.unit_num,
          unit_num_ratio: res.data.data.unit_num_ratio,
          unit_asset: res.data.data.unit_asset,
          old_year_earning: res.data.data.old_year_earning,
          old_year_profit: res.data.data.old_year_profit,
          old_year_government: res.data.data.old_year_government,
          old_year_expenditure: res.data.data.old_year_expenditure,
          old_year_ratio: res.data.data.old_year_ratio,
        };
        this.xinxi1 = {
          id: res.data.data.id,
          type: 2,
          recommend: res.data.data.recommend,
          recommend_zhuanjia: res.data.data.recommend_zhuanjia,
          recommend_credit_code: res.data.data.recommend_credit_code,
          recommend_code: res.data.data.recommend_code,
          mailing_address: res.data.data.mailing_address,
          recommend_name: res.data.data.recommend_name,
          recommend_tel: res.data.data.recommend_tel,
          recommend_email: res.data.data.recommend_email,
          recommend_mobile: res.data.data.recommend_mobile,
          recommend_opinion: res.data.data.recommend_opinion,
          recommend_date: res.data.data.recommend_date,
        };
        if (res.data.data.user_data != null) {
          this.url = res.data.data.user_data;
          this.fileList.push(this.url);
        }
      });
    },
    xiaxia() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新奖（单位）",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 1,
              token: sessionStorage.getItem("Token"),
              type_name: "2023年创新奖（单位）申报页面",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                innovation_unit_will(this.fengmian).then((res) => {
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    // this.num = 1;
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    insert: function () {
      this.stu.push(this.user);
      this.user = {};
    },
    remove: function (index) {
      this.stu.splice(index, 1);
    },
    daochus() {
      let p = {
        user_split_id: this.id,
        token: sessionStorage.getItem("Token"),
      };
      file_download(p).then((res) => {
        let blob = new Blob([res.data], { type: "application/zip" });
        let url = window.URL.createObjectURL(blob);
        const link = document.createElement("a"); // 创建a标签
        link.setAttribute(
          "download",
          sessionStorage.getItem("user_name") + "创新奖（单位）"
        );
        link.href = url;
        link.click();
        URL.revokeObjectURL(url); // 释放
      });
    },
    ylan() {
      window.open(this.url);
    },
    xiaxiadayin() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "产学研合作创新奖",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=首页`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    kan(e) {
      if (!e) {
        alert("请上传pdf在预览");
        return;
      }
      window.open(e, "_blank");
    },
    uploadFile(item) {
      //我的项目要求formdata上传数据
      console.log(item.file);
      console.log(this.fileList);
      const extension = item.file.name.split(".")[1] === "pdf";
      const extension2 = item.file.name.split(".")[1] === "PDF";
      if (!extension && !extension2) {
        this.$message.error("上传模板只能是pdf格式!");
        return;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      console.log(item.file);
      upload_file(form).then((res) => {
        console.log(res);
        console.log(this.fileList);
        if (res.data.code == 200) {
          this.$message({
            message: "添加成功",
            type: "success",
          });
          // this.arr.forEach((item, index) => {
          //   if (item.category == 1) {
          //   }
          // });
          this.url = res.data.url;
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile1(item) {
      //我的项目要求formdata上传数据
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("type", 2);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory1 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile2(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      form.append("type", 2);
      upload_file(form).then((res) => {
        console.log(res);
        console.log(this.fileList);
        if (res.data.code == 200) {
          this.obj.accessory2 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile3(item) {
      //我的项目要求formdata上传数据
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory3 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile4(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory4 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile5(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory5 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile6(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory6 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile7(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory7 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile8(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory8 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile9(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory9 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    uploadFile10(item) {
      const extension = item.file.name.split(".").slice(-1) == "pdf";
      if (!extension) {
        this.$message.error("上传模板只能是pdf格式!");
        return false;
      }
      const isLt10M = item.file.size / 1024 / 1024 > 30;
      if (isLt10M) {
        this.$message.error("最大不能超过30MB!");
        return;
      }
      this.normal = [];
      const form = new FormData();
      form.append("file", item.file);
      form.append("token", sessionStorage.getItem("Token"));
      upload_file(form).then((res) => {
        if (res.data.code == 200) {
          this.obj.accessory10 = res.data.url;
          this.$message({
            message: "添加成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
        // this.fileList.forEach((item) => {
        //   item.src = res.data.url;
        // });
      });
    },
    handlePictureCardPreview(file) {
      console.log(file);
      // window.open(
      //   `https://view.officeapps.live.com/op/view.aspx?src=${file.src}`
      // );
    },
    handleChange(file, fileList) {
      console.log(file);
      this.fileList = fileList.slice(-1);
      // let that = this;
      // var reader = new FileReader();
      // reader.readAsArrayBuffer(file.raw);
      // reader.onload = function (e) {
      //   const buffer = e.target.result; // 此时是arraybuffer类型
      //   mammoth
      //     .convertToHtml({ arrayBuffer: buffer })
      //     .then((result) => {
      //       console.log(result);
      //       that.vHtml = result.value;
      //     })
      //     .done();
      // };
    },
    xiazai() {
      let p = {
        id: 2,
      };
      award_find(p).then((res) => {
        console.log(res);
        window.location.href = res.data.data.file_url;
      });
    },
    dayin() {
      console.log("打印");
      printJS({
        printable: "printTest",
        type: "html",
        repeatTableHeader: true,
        scanStyles: true,
        maxWidth: "100%",
        targetStyles: ["*"],
        ignoreElements: ["no-print", "bc", "gb"],
      });
    },
    clickXia() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 2,
              token: sessionStorage.getItem("Token"),
              type_name: "申报单位基本信息",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi.type = 1;
                this.xinxi.token = sessionStorage.getItem("Token");
                innovation_unit_will(this.xinxi).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    // this.isShow = !this.isShow;
                    // console.log(this.isShow);
                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    clickXias() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=基本信息`
            );
            // preview(res.data.url).then((res) => {
            //   console.log(res);
            // });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    clickXia1() {
      this.isShow = !this.isShow;
    },
    fanhui() {
      this.num = 1;
    },
    fanhui1() {
      this.num = 2;
    },
    xiayibu1() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            console.log(res);
            let params = {
              user_split_id: this.user_split_id, //申报记录ID2
              path_url: res.data.url, //url
              type: 3,
              token: sessionStorage.getItem("Token"),
              type_name: "推荐意见",
            };
            user_file(params).then((res) => {
              console.log(res);
              if (res.data.msg == "成功") {
                this.xinxi1.token = sessionStorage.getItem("Token");
                innovation_unit_will(this.xinxi1).then((res) => {
                  console.log(res);
                  if (res.data.code == 200) {
                    this.$message({
                      message: "保存成功",
                      type: "success",
                    });
                    // this.num = 2;
                    this.user_chile_details();
                  } else {
                    this.$message.error(res.data.msg);
                  }
                });
              } else {
                alert("保存失败，请重新下一步");
              }
            });
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    xiayibu1s() {
      this.iss = !this.iss;
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "创新成果奖申报",
          true
        );
        setTimeout(() => {
          console.log(this.$myfile);
          const form = new FormData();
          form.append("file", this.$myfile);
          form.append("token", sessionStorage.getItem("Token"));
          upload_pdf(form).then((res) => {
            window.open(
              `${window.location.protocol}//${window.location.host}/common/2022/download_file?path=${res.data.url}&file_name=推荐意见`
            );
          });
        }, 1000);

        this.iss = !this.iss;
      }, 1000);
    },
    xiayibu2() {
      this.num = 3;
    },
    xiayibu3() {
      this.num = 4;
    },
    wancheng() {
      this.obj.id = this.id;
      innovation_unit_will(this.obj).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    wancheng1() {
      if (!this.showss) {
        alert("请勾选");
        return;
      }

      this.$confirm("确认所有资料提交给单位管理员").then((_) => {
        console.log(_);
        let obj = {
          token: sessionStorage.getItem("Token"),
          id: this.id,
          type: 6,
        };
        innovation_unit_will(obj)
          .then((res) => {
            if (res.data.code == 200) {
              this.centerDialogVisible = true;
              // this.num = 3;
            } else {
              this.$message.error(res.data.msg);
            }
          })
          .catch((_) => {});
      });
    },

    xiaoxiabu8() {
      this.num = 3;
    },
    xiaoxiabu() {
      let params = {
        token: sessionStorage.getItem("Token"),
        id: this.id,
        type: 3,
        user_data: this.url,
      };
      innovation_unit_will(params).then((res) => {
        if (res.data.code == 200) {
          this.$message({
            message: "保存成功",
            type: "success",
          });
          // this.num = 3;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },
    shangyibu() {
      this.num = 1;
    },
    baocun() {
      this.is = !this.is;
      console.log(this.is);
      setTimeout(() => {
        console.log(document.querySelector("#printTest"));
        this.$downloadPDF.downloadPDF(
          document.querySelector("#printTest"),
          "2023年创新奖（单位）"
        );
        this.is = !this.is;
      }, 1000);
    },
    readSiemens() {
      console.log(xinxi1.recommend_opinion);
    },
  },
};
</script>

<style scoped>
.nav {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}
.present {
  width: 100%;
  height: 100%;
}
.main {
  width: 100%;
  height: calc(100% - 50px);
}
.shenMain {
  width: 100%;
  /* height: calc(100% - 100px); */
  height: auto;
  margin: 0 auto;
}
.shenMain table {
  width: 1300px;
  margin: 20px 0;
  border-collapse: collapse;
}
.shenMain tr {
  height: 75px;
}
.shenMain td {
  width: 150px;
  text-align: center;
  font-size: 24px;
}
caption {
  font-size: 28px;
  margin: 40px;
}
textarea {
  background: none;
  outline: none;
  border: 0px;
  width: 920px;
  height: 200px;
  font-size: 24px;
}
.tabls input {
  background: none;
  outline: none;
  border: 0px;
  font-size: 24px;
  width: 90px;
}
.tabls_bom {
  width: 100%;
  display: flex;
  padding: 24px;
  box-sizing: border-box;
  justify-content: space-around;
}
.tabls_bom1 {
  width: 400px;
  display: flex;
  position: fixed;
  bottom: 20px;
  padding: 24px;
  left: 47%;
  box-sizing: border-box;
  justify-content: space-between;
}
.tabls_bom1 > div {
  width: 40%;
}
/* .tabls {
  display: flex;
  justify-content: center;
  align-items: center;
} */
.tabls > div {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.biaoge {
  width: 100%;
  height: 90%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  position: relative;
  /* margin: 20px auto; */
  /* justify-content: space-between; */
}
.biaoge > div:nth-child(1) {
  text-indent: 2em;
  text-align: left;
}
.zunshou {
  height: 100%;
  text-indent: 2em;
  text-align: left;
  box-sizing: border-box;
  padding: 0 10px;
}
.zunshou div {
  margin-top: -50px;
}
.biaoge > div:nth-child(2) {
  display: flex;
  box-sizing: border-box;
  padding: 0 60px;
  margin-top: 400px;
  justify-content: space-between;
}
.biaoge > div:nth-child(2) > div {
  width: 350px;
}
.shangchuan {
  margin: 50px;
}
.shangchuan1 {
  margin: 50px;
}
.shangchuan > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.clickXia1_main {
  position: fixed;
  bottom: 20px;
  display: flex;
  width: 75%;
  justify-content: space-around;
  align-items: center;
}
.clickXia1_main1 {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
.clickXia1_main1 > div {
  margin-left: 50px;
}
.tables {
  width: 100%;
  margin-top: 20px;
  box-sizing: border-box;
  padding: 0 10px;
}
#table {
  width: 100%;
  border-collapse: collapse;
}
#table input {
  background: none;
  outline: none;
  border: 0px;
  width: 150px;
}
#table td {
  width: 80px;
}
.box {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-bottom: 24px;
}
.box > div:nth-child(1) {
  width: 700px;
}
.box > div:nth-child(2) {
  width: 400px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.fengmian {
  width: 1200px;
  height: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  padding-bottom: 100px;
  padding-top: 300px;
  align-items: center;
}
.fengmian > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.fengmian > div:nth-child(1) > div {
  font-size: 40px;
}
.fengmian > div:nth-child(2) > div {
  display: flex;
  align-items: center;
}
.fengmian > div:nth-child(2) > div > div:nth-child(1) {
  width: 240px;
  font-size: 22px;
}
.fengmian > div:nth-child(2) > div > input {
  background: none;
  outline: none;
  border: 0px;
  font-size: 24px;
  border-bottom: 1px solid #999;
}

.clickXia1 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
}
.clickXia2 {
  width: 150px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #409eff;
  color: #fff;
  margin-top: 60px;
  margin-right: 10px;
}
.faren {
  width: 100%;
  margin-top: 100px;
  display: flex;
  justify-content: center;
}
.yiqi {
  display: flex;
  height: calc(100vh - 170px);
  /* background-color: pink; */
}
.yiqi > div:nth-child(1) {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.yiqi > div:nth-child(1) > div {
  width: 180px;
  color: rgba(70, 57, 57, 0.83);
  cursor: pointer;
  font-size: 20px;
  margin: 15px 0;
}
.yiqi > div:nth-child(1) > div i {
  margin-right: 5px;
}
.yiqi > div:nth-child(1) {
  width: 15%;
  background-color: #fff;
  cursor: pointer;
  height: 100%;
}
.yiqi > div:nth-child(3) {
  box-sizing: border-box;
  padding: 20px;
}
.yiqi > div:nth-child(2) {
  overflow-y: scroll;
  height: 100%;
  flex: 1;
  margin: 0 10px;
  background-color: #fff;
}
.yiqi > div:nth-child(1) .colors {
  color: #409eff;
}
.feng_btn {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}
.chea {
  margin: 0;
}
.zuihou {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}
.zuihou div {
  width: 800px;
  margin: 40px;
}
.tijiaos {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.tijiaos > div {
  font-size: 20px;
}
.tijiaos > div:nth-child(1) {
  font-size: 70px;
  font-weight: bold;
}
.tijiaos > div:last-child {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.tijiaos > div:last-child > div {
  width: 150px;
  border-radius: 10px;
  height: 40px;
  background: #409efe;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  margin-left: 30px;
}
.tijiaos > div {
  margin: 10px 0;
}
.biaogesss {
  height: 220px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  box-sizing: border-box;
  padding: 0 10px;
}
.biaoges {
  box-sizing: border-box;
  padding: 0 10px;
  position: absolute;
}
/* .feng_btn > div {
  padding: 10px;
} */
</style>
